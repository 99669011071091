<template>
  <div
    class="rotation-animation"
    :class="{
      'cursor-pointer': isClickable,
    }"
    :style="useCase == 'none' ? buttonStyleNone : buttonStyle"
    @click="$emit('click')"
  >
    <span
      :class="{
        'button-content': useCase != 'none',
        'button-content-none': useCase == 'none',
      }"
    >
      <span>{{ message }}</span>
      <span class="separator" v-if="isClickable"></span>

      <span class="d-flex align-items-center" v-if="isClickable">
        Click Here
        <img src="@/assets/icon/chevron-right.svg" alt="Chevron Right icon" />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      buttonStyle: {
        background:
          "conic-gradient(from calc(var(--r2)) at 50% 50%, transparent 0, #0E4485 20%, transparent 25%)", // Centered conic gradient
      },
      buttonStyleNone: {
        background:
          "conic-gradient(from calc(var(--r2)) at 50% 50%, transparent 0, #fca5a5 20%, transparent 25%)", // Centered conic gradient
      },
    };
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    useCase: {
      type: String,
      required: true,
    },
    isClickable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style scoped>
@property --r2 {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}

.cursor-pointer {
  cursor: pointer;
}
.rotation-animation {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 2px 2px; /* Keep existing padding */
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  scale: 1;
  transition: background 0.3s, box-shadow 0.3s, scale 0.3s;
  animation: rotateGradient 5s linear infinite; /* Smooth rotation */
  overflow: hidden;
}

/* Keyframes for gradient rotation */
@keyframes rotateGradient {
  0% {
    --r2: 0deg; /* Start at 0 degrees */
  }
  100% {
    --r2: 360deg; /* Rotate to 360 degrees */
  }
}

.button-content {
  display: flex;
  align-items: center;
  background-color: #fbfbfb;
  padding: 0; /* Keep existing padding */
  color: #0e4485;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 5px 10px; /* Keep existing padding */
  border-radius: 10px;
}

.button-content-none {
  display: flex;
  align-items: center;
  background-color: #fee2e2;
  padding: 0; /* Keep existing padding */
  color: #dc2626;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 5px 10px; /* Keep existing padding */
  border-radius: 10px;
}
.separator {
  height: 1rem;
  width: 1px;
  background-color: #73737c;
  margin: 0 10px;
}

.rotation-animation:hover {
  background-color: #b2ebf2;
  scale: 1.01;
}
</style>
