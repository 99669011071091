<template>
  <div class="custom-badge" @click="$emit('click')">
    <div style="width: 80%; left: 0; margin-left: 0">
      <slot />
    </div>
    <span class="separator"></span>

    <span class="d-flex align-items-center">
      Change Question
      <img src="@/assets/icon/chevron-right.svg" alt="Chevron Right icon" />
    </span>
  </div>
</template>

<script>
export default {
  name: "Badge",
};
</script>

<style scoped>
.separator {
  height: auto;
  width: 1px;
  background-color: #73737c;
  margin: 0 10px;
}
.custom-badge {
  display: inline-flex;
  padding-top: 0.25rem;
  font-size: 0.75rem;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: rgba(215, 224, 235, 1); /* Light red background */
  border: 1px solid rgba(13, 68, 133, 0.5); /* Light red border */
  color: #0d4485; /* Text color */
  padding: 8px 12px; /* Equivalent to px-3 */
  border-radius: 8px; /* Rounded corners */
  gap: 4px; /* Space between icon and text */
}

.custom-badge .icon {
  width: 24px;
  height: 24px;
  stroke: currentColor; /* Use current text color */
}
</style>
